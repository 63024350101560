<template>
    <div class="chanel"></div>
</template>

<script>
    export default {
        name: "index"
    }
</script>

<style scoped>

</style>
